import React from "react";
import { Col, Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { menuItems } from "../../../data/menudata";
import Link from "../Link";


const isObject = function (a) {
  return !!a && a.constructor === Object
}
const Menu = ({...rest}) => {

  return (
    <>
      <Navbar.Collapse id="responsive-navbar-nav" {...rest}>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="d-lg-none offcanvas-close"/>
          </Navbar.Collapse>
          </>
  );
};
export default Menu;
